<app-navbar></app-navbar>
<div class="main-content min-vh-100">


  <section>
    <div class="container">
      <div class="row mt-3">
        <div class="col">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a [routerLink]="PathRoutes.Account.Detail">Minha conta</a>
              </li>
              <li class="breadcrumb-item active">
                Editar
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col">
          <h2 class="mb-0 pt-3">
            Editar conta
          </h2>
        </div>

        <div class="col-12">
          <hr>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
          <form [formGroup]="form" (submit)="save()">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Nome</label>
                  <input type="text" formControlName="name" class="form-control shadow-sm" placeholder="Nome"
                    [ngClass]="{'is-invalid': form.controls.name.touched && form.controls.name.invalid }">
                  <small *ngIf="form.controls.name.touched && form.controls.name.invalid" class="text-danger">Preencha o
                    nome</small>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Email</label>
                  <input type="text" formControlName="email" class="form-control shadow-sm" placeholder="Email"
                    [ngClass]="{'is-invalid': form.controls.email.touched && form.controls.email.invalid }">
                  <small *ngIf="form.controls.email.touched && form.controls.email.invalid" class="text-danger">Preencha
                    o
                    email</small>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label>Empresa</label>
                  <input type="text" formControlName="company" class="form-control shadow-sm" placeholder="Empresa"
                    [ngClass]="{'is-invalid': form.controls.company.touched && form.controls.company.invalid }">
                  <small *ngIf="form.controls.company.touched && form.controls.company.invalid"
                    class="text-danger">Preencha
                    a
                    empresa</small>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label>Documento</label>
                  <input type="text" formControlName="document" class="form-control shadow-sm" placeholder="Documento"
                    [ngClass]="{'is-invalid': form.controls.document.touched && form.controls.document.invalid }"
                    [mask]="helper.masks.cpf">
                  <small *ngIf="form.controls.document.touched && form.controls.document.invalid"
                    class="text-danger">Preencha
                    o
                    documento</small>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label>Telefone</label>
                  <input type="text" formControlName="phone" class="form-control shadow-sm" placeholder="Telefone"
                    [ngClass]="{'is-invalid': form.controls.phone.touched && form.controls.phone.invalid }"
                    [mask]="helper.masks.mobile">
                  <small *ngIf="form.controls.phone.touched && form.controls.phone.invalid" class="text-danger">Preencha
                    o
                    telefone</small>
                </div>
              </div>
              <div class="col-12">
                <a class="btn btn-block btn-light mt-4" [routerLink]="PathRoutes.Account.ChangePassword">
                  <span>Alterar senha</span>
                </a>
              </div>
              <div class="col-12">
                <button type="submit" class="btn btn-block btn-primary mt-2" [disabled]="form.invalid">
                  <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span *ngIf="!loading">
                    Concluir
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>
