import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './routes/app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { CurrencyMaskModule, CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import { NgxMaskModule } from 'ngx-mask';


import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './pages/login/forgot-password/forgot-password.component';
import { AccountComponent } from './pages/account/account.component';
import { AccountEditComponent } from './pages/account/account-edit/account-edit.component';
import { AccountChangePasswordComponent } from './pages/account/account-change-password/account-change-password.component';
export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    NotFoundComponent,
    ProjectDetailsComponent,
    PaymentComponent,
    ForgotPasswordComponent,
    AccountComponent,
    AccountEditComponent,
    AccountChangePasswordComponent,
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    NgxMaskModule.forRoot(),
    CurrencyMaskModule



  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
