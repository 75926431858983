import { AccountChangePasswordComponent } from './../pages/account/account-change-password/account-change-password.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PathRoutes } from './path-routes';
import { HomeComponent } from './../pages/home/home.component';
import { NotFoundComponent } from '../pages/not-found/not-found.component';
import { ProjectDetailsComponent } from '../pages/project-details/project-details.component';
import { PaymentComponent } from '../pages/payment/payment.component';
import { LoginComponent } from '../pages/login/login.component';

import { AuthService } from '../auth/auth.service';
import { ForgotPasswordComponent } from '../pages/login/forgot-password/forgot-password.component';
import { AccountComponent } from '../pages/account/account.component';
import { AccountEditComponent } from '../pages/account/account-edit/account-edit.component';


const routes: Routes = [
  { path: PathRoutes.Login.replace('/', ''), component: LoginComponent },
  { path: PathRoutes.ForgotPassword.replace('/', ''), component: ForgotPasswordComponent },

  { path: '', component: HomeComponent, canActivate: [AuthService] },
  { path: PathRoutes.Home.replace('/', ''), component: HomeComponent, canActivate: [AuthService] },
  { path: PathRoutes.ProjectDetails.replace('/', '') + '/:id', component: ProjectDetailsComponent, canActivate: [AuthService] },
  { path: PathRoutes.Payment.replace('/', ''), component: PaymentComponent, canActivate: [AuthService] },
  { path: PathRoutes.Account.Detail.replace('/', ''), component: AccountComponent, canActivate: [AuthService] },
  { path: PathRoutes.Account.Edit.replace('/', ''), component: AccountEditComponent, canActivate: [AuthService] },
  { path: PathRoutes.Account.ChangePassword.replace('/', ''), component: AccountChangePasswordComponent, canActivate: [AuthService] },

  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
