import { ValidatorFn, AbstractControl, FormArray } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorsService {

  constructor() { }

  public options(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			if (control.value !== undefined && this.validateOptions(control.value)) {
				return null;
      }
			return { 'options': true };
		};
  }
  
  validateOptions(arr){
    if(arr == null){
      return false
    }
    return arr.filter(elem => elem.hotel_id).length > 0
  }

  public categories(min = 1){
    const validator = (formArray: FormArray) => {
      const totalChecked = formArray.controls
      .map(v => v.value)
      .reduce((total, current) => current ? total + current : total, 0)
      return totalChecked >= min ? null : { required:true }
    }
    return validator
  }
}
