import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helpers/helper.service';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/auth/session.service';
import { ClientsService } from 'src/app/services/clients.service';

@Component({
  selector: 'app-account-change-password',
  templateUrl: './account-change-password.component.html',
  styleUrls: ['./account-change-password.component.scss']
})
export class AccountChangePasswordComponent implements OnInit {
  PathRoutes = PathRoutes
  form: FormGroup
  loading = false
  id: any

  constructor(
    public helper: HelperService,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private router: Router,
    private clientsService: ClientsService
  ) { }

  ngOnInit(): void {
    this.id = this.sessionService.getSession().id
    this.setupForm()
  }

  setupForm() {
    this.form = this.formBuilder.group({
      'id': this.id,
      'old_password': ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      'confirm_password': ['', Validators.compose([Validators.required, Validators.minLength(8)])],
    })
  }

  save() {
    this.loading = true
    this.clientsService.save(this.form.getRawValue())
      .then((res: any) => {
        if (res.status) {
          this.helper.triggerNotification(true)
          this.router.navigate([PathRoutes.MyEstablishment.Detail])
        } else {
          this.helper.triggerNotification(false)
          this.loading = false
        }
      })
      .catch(e => console.log(e))
      .finally(() => this.loading = false)
  }
}
