import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { Endpoints } from '../routes/endpoints';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    private network: NetworkService
  ) { }

  list(page = 1, q = '') {
    let url = `${Endpoints.Users}` + '?type=client&page='+page+ '&q=' + q
    return this.network.get(url)
  }
  adminList(page = 1, q = '') {
    let url = `${Endpoints.Users}` + '?type=admin&page='+page+ '&q=' + q
    return this.network.get(url)
  }

  show(id) {
    let url = `${Endpoints.Users}/${id}`
    return this.network.get(url)
  }

  save(params) {
    let id = params.id
    delete (params.id)
    if (id) {
      let url = `${Endpoints.Users}/${id}`
      return this.network.put(url, params)
    } else {
      let url = `${Endpoints.Users}`
      return this.network.post(url, params)
    }
  }
  


  getCep(cep) {
    return this.network.makeExternalRequest(`https://viacep.com.br/ws/${cep.replace('-', '')}/json/`, null, 'get')
  }

  delete(id) {
    let url = `${Endpoints.Users}/${id}`
    return this.network.delete(url)
  }

}
