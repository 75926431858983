import { SessionService } from 'src/app/auth/session.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  baseUrl = environment.baseUrl

  constructor(
    private http: HttpClient,
    private router: Router,
    private session: SessionService
  ) { }

  post(endPoint, params) {
    return this.http.post(this.baseUrl + endPoint, params, { headers: this.headers() }).toPromise()
  }
  get(endPoint) {
    return this.http.get<any>(this.baseUrl + endPoint, { headers: this.headers() }).toPromise()
  }
  put(endPoint, params) {
    return this.http.put(this.baseUrl + endPoint, params, { headers: this.headers() }).toPromise()
  }
  delete(endPoint) {
    return this.http.delete(this.baseUrl + endPoint, { headers: this.headers() }).toPromise()
  }

  makeExternalRequest(endPoint, params, type) {
    switch (type) {
      case "post":
        return this.http.post(endPoint, params).toPromise();

      case "get":
        return this.http.get<any>(endPoint).toPromise();

      case "put":
        return this.http.put(endPoint, params).toPromise();
    }
  }

  postMultipart(endPoint, params) {
    // var token = localStorage.getItem('token')
    // console.log(token)
    // if (token == null) {
    //   token = ''
    // }
    return this.http.post<any>(this.baseUrl + endPoint, this.makeItMultipartParams(params), { headers: this.headers(true) }).toPromise()

  }


  putMultipart(endPoint, params) {
    // var token = localStorage.getItem('access_token')
    // console.log(token)
    // if (token == null) {
    //   token = ''
    // }
    return this.http.put<any>(this.baseUrl + endPoint, this.makeItMultipartParams(params), { headers: this.headers(true) }).toPromise()

  }



  makeItMultipartParams(params) {

    var p = new FormData()
    Object.keys(params).forEach(function (key, index) {
      if (Array.isArray(params[key])) {
        params[key].map(r => {
          p.append(`${key}[]`, r)
        })
      } else {
        // console.log(key)
        // console.log(params[key])
        p.append(key, params[key])
      }

    });
    return p
  }

  headers(multipart = false) {

    var r = this.session.getSession()

    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (multipart) {
      delete(headers['Content-Type'])
    }
    if (r) {
      headers['Authorization'] = `Bearer ${r.access_token}`
    }

    //  var h = {
    //   headers: headers
    // }
    // console.log(h)
    return headers
  }
}
