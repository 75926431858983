export const PathRoutes = Object({
  Login: '/login',
  Home: '/home',
  ProjectDetails: '/detalhes-do-projeto',
  Payment: '/pagamento',
  ForgotPassword: '/esqueci-minha-senha',
  Account: {
    Detail: '/minha-conta',
    Edit: '/minha-conta/editar',
    ChangePassword: '/minha-conta/alterar-senha'
  }
});



