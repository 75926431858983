import { SessionService } from './../../auth/session.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PathRoutes } from './../../routes/path-routes';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  session: any
  PathRoutes = PathRoutes

  constructor(private sessionService: SessionService, private router: Router) { }

  ngOnInit(): void {
    this.session = this.sessionService.getSession()
  }

  logout() {
    this.sessionService.destroySession()
    this.router.navigate([PathRoutes.Login])
  }

}
