import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PathRoutes } from './../../routes/path-routes';
import { environment } from 'src/environments/environment';


import { SessionService } from 'src/app/auth/session.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { HelperService } from 'src/app/helpers/helper.service';


@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {

  s3_base_url = environment.s3_base_url

  environment = environment

  PathRoutes = PathRoutes
  currentTab = 'financial'
  id
  item
  paymentHistory: any
  devProgress = 0
  maintenanceProgress = 0
  loading = false


  constructor(
    private sessionService: SessionService,
    private projectsService: ProjectsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public helper: HelperService,

  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.projectInfo()
  }

  changeTab(tabName) {
    this.currentTab = tabName
  }
  projectInfo() {
    this.projectsService.projectDetail(this.id).then((res) => {

      var totalProgress = 0
      this.item = res.data

      this.item.overduePayment = false

      this.item.products.map(p => {

        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        const from = new Date(p.start_date);
        const to = new Date(p.due_date);
        const today = new Date();
        const toUtc = Date.UTC(to.getFullYear(), to.getMonth(), to.getDate());
        const fromUtc = Date.UTC(from.getFullYear(), from.getMonth(), from.getDate());
        const todayUtc = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());

        const diffDays = Math.floor((toUtc - fromUtc) / _MS_PER_DAY);
        const remainingTimeInDays = Math.floor((toUtc - todayUtc) / _MS_PER_DAY);

        const currentAt = diffDays - remainingTimeInDays

        p.progress = Math.round((currentAt * 100) / diffDays)

        totalProgress += p.progress
        p.diffDays = diffDays


        if (p.progress > 100) p.progress = 100
        if (p.progress < 0) p.progress = 0


      })

      if (totalProgress > 0) {

        if (totalProgress < this.item.products.length * 100) {
          this.devProgress = 1 // durante desenvolvimento
        } else {
          this.devProgress = 2 // finalizado desenvolvimento
        }

      } else {
        this.devProgress = 0 // nao iniciou desenvolvimento
      }



      this.item.recurring.map(r => {
        if (r.type == 'maintenance') {
          if (r.status == 'paid') {
            this.maintenanceProgress = 1;
          }
        }

      })
      this.item.totalPayment = 0
      this.item.totalRecurring = 0

      this.item.payments.map(p => {
        this.item.totalPayment += p.value

        const due_date = new Date(p.due_date);
        const today = new Date();
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;


        const due_dateUtc = Date.UTC(due_date.getFullYear(), due_date.getMonth(), due_date.getDate());
        const todayUtc = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());

        const diffDays = Math.floor((due_dateUtc - todayUtc) / _MS_PER_DAY);

        p.diffDays = diffDays

        if (p.status == 'pending' && p.diffDays <= 0 && p.source == 'pagarme') {
          p.shouldPay = true
          this.item.overduePayment = true
        } else {
          p.shouldPay = false

        }

        p.type = 'payment'


      })

      this.item.recurring.map(p => {
        this.item.totalRecurring += p.value

        const due_date = new Date(p.due_date);
        const today = new Date();
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;


        const due_dateUtc = Date.UTC(due_date.getFullYear(), due_date.getMonth(), due_date.getDate());
        const todayUtc = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());

        const diffDays = Math.floor((due_dateUtc - todayUtc) / _MS_PER_DAY);

        p.diffDays = diffDays

        // if (p.status == 'pending' && p.diffDays <= 0 && p.source == 'pagarme') {
        if ((p.status == 'pending' ) && p.source == 'pagarme') {

          p.shouldPay = true
          this.item.overduePayment = true

        } else {
          p.shouldPay = false

        }

        p.type = 'recurring'

        console.log('should pay: ', p.shouldPay)



      })
      console.log(this.item)


    })
  }

  setPaymentHistory(id) {
    this.loading = true
    this.projectsService.getPaymentHistory(id)
      .then((res: any) => {
        if (res.status) {
          this.paymentHistory = res.data
        } else {
          this.loading = false
        }
      })
      .catch(e => {
        this.loading = false
        console.log(e)
      })
      .finally(() => {
        this.loading = false
      })
  }

  icon(type) {
    switch (type) {
      case 'webapp':
      case 'landing':
        return 'fas fa-globe'
      case 'ios':
        return 'fab fa-apple'
      case 'android':
        return 'fab fa-android'
      case 'admin':
        return 'fas fa-user-cog'
      default:
        return 'fas fa-globe'
    }
  }
}
