<section class="min-vh-100 py-5">
  <div class="container">
    <div class="row justify-content-center mb-md-6">
      <div class="col-auto">
        <a href="index.html">
          <img src="assets/img/logo.svg" alt="Leap">
        </a>
      </div>
    </div>
    <div class="row justify-content-center pt-6">
      <div class="col-xl-4 col-lg-5 col-md-6">
        <div class="text-center mb-4">
          <h1 class="mb-1">Área do cliente</h1>
          <span>Informe abaixo seu email e sua senha.</span>
        </div>
        <form [formGroup]="form" (submit)="login()">
          <div class="form-group">
            <input type="email" formControlName="email" placeholder="Email" class="form-control"
            [ngClass]="{'is-invalid': form.controls.email.touched && form.controls.email.invalid}">
        <small *ngIf="form.controls.email.touched && form.controls.email.invalid"
            class="text-danger">Digite o email</small>
          </div>
          <div class="form-group">
            <input type="password" formControlName="password" placeholder="Senha" class="form-control"
                            [ngClass]="{'is-invalid': form.controls.password.touched && form.controls.password.invalid}">
                        <small *ngIf="form.controls.password.touched && form.controls.password.invalid"
                            class="text-danger">Digite a senha</small>
            <small><a [routerLink]="PathRoutes.ForgotPassword">Esqueceu sua senha?</a>
            </small>
          </div>
          <div class="form-group text-center" *ngIf="message">
            <small class="text-danger">{{ message }}</small>
        </div>
          <div class="form-group">
            <button class="btn-block btn btn-primary" type="submit" [disabled]="form.invalid">
              <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <span *ngIf="!loading">
                Acessar
            </span>
            </button>
          </div>
          <!-- <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="login-remember">
            <label class="custom-control-label text-small text-muted" for="login-remember">Keep me signed in</label>
          </div> -->
          <!-- <hr>
          <div class="text-center text-small text-muted">
            <span>Don't have an account yet? <a href="#">Create one</a>
            </span>
          </div> -->
        </form>
      </div>
    </div>
  </div>
</section>
