import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { Endpoints } from '../routes/endpoints';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private network: NetworkService
  ) { }

  pay(type,id,params) {
    var url 
    if(type == 'payment'){
       url = `${Endpoints.ProjectPayments}` + '/pay/' + id

    } else if (type == 'recurring') {
       url = `${Endpoints.ProjectRecurring}` + '/pay/' + id
    }
      return this.network.post(url, params)
  }

  

}
