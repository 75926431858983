<div class="loader">
  <div class="loading-animation"></div>
</div>

<section class="min-vh-100 py-5">
  <div class="container">
    <div class="row justify-content-center mb-md-6">
      <div class="col-auto">
        <a href="/">
          <img src="assets/img/logo.svg" alt="Leap">
        </a>
      </div>
    </div>
    <div class="row justify-content-center pt-6">
      <div class="col-xl-4 col-lg-5 col-md-6">
        <div class="text-center mb-4">
          <h1 class="mb-1">Esqueci minha senha</h1>
          <span>Digite seu email para receber o link para recadastramento de sua senha</span>
        </div>

        <form class="mb-6" [formGroup]="form" (submit)="forgotPassword()">

          <div class="form-group">
            <input type="email" formControlName="email" class="form-control" id="email" placeholder="Digite seu email"
              [ngClass]="{'is-invalid': form.controls.email.touched && form.controls.email.invalid}">
            <small class="text-danger" *ngIf="form.controls.email.touched && form.controls.email.invalid">Digite seu
              email</small>
          </div>
          <div class="form-group">
            <button class="btn btn-block btn-primary" [disabled]="form.invalid">
              Enviar
              <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
          </div>
          <p class="mb-0 font-size-sm text-muted">
            Lembrou de sua senha? <a routerLink="{{PathRoutes.Login}}">Acesse</a>.
          </p>

        </form>
      </div>
    </div>
  </div>
</section>
