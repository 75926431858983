
export const Endpoints = Object.freeze({
  Login: 'login',
  Register: 'register',

  Admins: 'admins',

  ///ph shit

  Users: 'users',
  Projects: 'projects',
  ProjectPayments: 'projects/payments',
  ProjectRecurring: 'projects/recurring-payments',
  ProjectComments: 'projects/comments',
  ProjectFiles: 'projects/files',
  PaymentHistory: 'projects/recurring-payments/history',

  ProjectProducts: 'projects/products',

  AdminHomeStats: 'home/stats',
  PasswordEmail: 'password/email'

});
