<app-navbar></app-navbar>
<div class="main-content min-vh-100">


  <section>
    <div class="container">
      <div class="row mt-3">
        <div class="col">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active">
                Minha conta
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col">
          <h2 class="mb-0 pt-3">
            Detalhes da conta
          </h2>
        </div>
        <div class="col-auto">
          <a [routerLink]="PathRoutes.Account.Edit" class="btn btn-primary">
            Editar
          </a>
        </div>
        <div class="col-12">
          <hr>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
          <fieldset disabled>
            <form [formGroup]="form">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Nome</label>
                    <input type="text" formControlName="name" class="form-control shadow-sm" placeholder="Nome">
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="text" formControlName="email" class="form-control shadow-sm" placeholder="Email">
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label>Empresa</label>
                    <input type="text" formControlName="company" class="form-control shadow-sm" placeholder="Empresa">
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label>Documento</label>
                    <input type="text" formControlName="document" class="form-control shadow-sm" placeholder="Documento"
                      [mask]="helper.masks.cpf">
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label>Telefone</label>
                    <input type="text" formControlName="phone" class="form-control shadow-sm" placeholder="Telefone"
                      [mask]="helper.masks.mobile">
                  </div>
                </div>
              </div>
            </form>
          </fieldset>
        </div>
      </div>
    </div>
  </section>

</div>



<app-footer></app-footer>
