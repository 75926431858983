<app-navbar></app-navbar>

<div class="main-content min-vh-100">
  <section>
    <div class="container">
      <div class="row justify-content-center mt-6">
        <div class="col-md-9 col-lg-8 col-xl-6">
          <div class="mb-4">
            <h2 class="h1">
              Realizar pagamento
            </h2>
            <p class="lead">Selecione o método de pagamento abaixo e concluar seu pagamento.
            </p>
          </div>
          <form [formGroup]="cardForm">
            <div class="row">
              <div class="col-12">
                <h4 class="mt-4">
                  1. Escolha um método de pagamento
                </h4>

                <div class="form-group">
                  <div class="custom-control custom-radio mb-2" *ngIf="item.method == 'credit_card'">
                    <input formControlName="method" value="credit_card" id="custom-radio-1" type="radio"
                      class="custom-control-input">
                    <label class="custom-control-label" for="custom-radio-1">
                      Cartão de crédito
                    </label>
                  </div>
                  <div class="custom-control custom-radio mb-2" *ngIf="item.method == 'boleto'">
                    <input formControlName="method" value="boleto" id="custom-radio-2" type="radio"
                      class="custom-control-input">
                    <label class="custom-control-label" for="custom-radio-2">
                      Boleto
                    </label>
                  </div>

                </div>

                <hr>
              </div>
            </div>
            <div class="row" *ngIf="cardForm.controls.method.value == 'credit_card'">
              <div class="col-12">
                <h4 class="mt-4">
                  2. Preencha as informações do cartão
                </h4>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Nome no cartão *</label>
                  <input name="contact-name" type="text" class="form-control" formControlName="card_holder_name"
                    placeholder="Qual nome está impresso no cartão?">
                  <div class="invalid-feedback">
                    Please type your name.
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Número do cartão *</label>
                  <input name="contact-email" type="email" formControlName="card_number" placeholder="you@yoursite.com"
                    class="form-control" required="" placeholder="Qual é o número do cartão?"
                    [mask]="helper.masks.credit_card">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Validade do cartão *</label>
                  <input name="contact-company" type="text" formControlName="card_expiration_date" class="form-control"
                    required="" placeholder="Qual é a validade do cartão?" mask="M0/00">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>CVV *</label>
                  <input name="contact-phone" type="tel" formControlName="card_cvv" class="form-control" required=""
                    placeholder="Qual é o código de segurança?" maxlength="4">
                </div>
              </div>
              <div class="col-12" *ngIf=!cardValidation.status>
                <div class="alert alert-danger" role="alert">
                  {{cardValidation.message}}
                </div>
              </div>
              <div class="col-12">
                <hr>
              </div>
            </div>
            <!-- <div class="col-12">
                <h4 class="mt-4">
                  3. Documento
                </h4>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>CPF *</label>
                  <input name="contact-name" type="text" class="form-control" required="" placeholder="Qual nome está impresso no cartão?">
                  <div class="invalid-feedback">
                    Digite seu CPF.
                  </div>
                </div>
              </div>
              <div class="col-12">
                <hr>
              </div>
              <div class="col-12">
                <h4 class="mt-4">
                  4. Endereço de cobrança
                </h4>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>CEP</label>
                  <input name="contact-email" type="email" placeholder="you@yoursite.com" class="form-control"
                    required="" placeholder="Inserir CEP do endereço de cobrança">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Endereço *</label>
                  <input name="contact-email" type="email" placeholder="you@yoursite.com" class="form-control"
                    required="" placeholder="Inserir endereço de cobrança">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Número *</label>
                  <input name="contact-email" type="email" placeholder="you@yoursite.com" class="form-control"
                    required="" placeholder="Inserir número de endereço">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Complemento</label>
                  <input name="contact-email" type="email" placeholder="you@yoursite.com" class="form-control"
                    required="" placeholder="Inserir complemento se houver">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Bairro *</label>
                  <input name="contact-email" type="email" placeholder="you@yoursite.com" class="form-control"
                    required="" placeholder="Inserir bairro">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Cidade *</label>
                  <input name="contact-email" type="email" placeholder="you@yoursite.com" class="form-control"
                    required="" placeholder="Inserir cidade">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Estado *</label>
                  <input name="contact-email" type="email" placeholder="you@yoursite.com" class="form-control"
                    required="" placeholder="Inserir estado">
                </div>
              </div>

              <div class="col-12">
                <hr>
              </div>-->
            <div class="row">
              <div class="col mt-4" *ngIf="boletoUrl">
                <!-- <h3>{{boletoUrl}}</h3> -->

                <p>Você pode visualizar abaixo seu boleto. Ele também está disponível na área financeira do seu projeto.</p>
                <iframe [src]="boletoUrl" width="100%" height="500"></iframe>
                <a [routerLink]="[PathRoutes.ProjectDetails + '/' + item.project_id]" class="btn btn-primary btn-loading btn-block"> Concluir</a>
              </div>
              <div class="col mt-4" *ngIf="!boletoUrl">
                <!-- <div class="d-none alert alert-success" role="alert" data-success-message="">
                  Thanks, a member of our team will be in touch shortly.
                </div>
                <div class="d-none alert alert-danger" role="alert" data-error-message="">
                  Please fill all fields correctly.
                </div> -->

                <button [disabled]="loading" (click)="submit()" type="submit"
                  class="btn btn-primary btn-loading btn-block" data-loading-text="Sending">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon"
                    data-src="assets/img/icons/theme/code/loading.svg">
                    <title>Icon For Loading</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g>
                        <polygon points="0 0 24 0 24 24 0 24" opacity="0"></polygon>
                      </g>
                      <path
                        d="M12,4 L12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,10.9603196 17.7360885,9.96126435 17.2402578,9.07513926 L18.9856052,8.09853149 C19.6473536,9.28117708 20,10.6161442 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z"
                        fill="#000000" fill-rule="nonzero"
                        transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) ">
                      </path>
                    </g>
                  </svg>
                  <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span *ngIf="!loading">Realizar pagamento</span>
                </button>


              </div>
            </div>


          </form>
        </div>
      </div>
    </div>
  </section>
</div>

<app-footer></app-footer>
