import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PathRoutes } from './../../routes/path-routes';
import { SessionService } from 'src/app/auth/session.service';
import { ProjectsService } from 'src/app/services/projects.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  PathRoutes = PathRoutes

  projects = []
  user

  constructor(
    private sessionService: SessionService,
    private projectsService: ProjectsService,



  ) { }

  ngOnInit(): void {
		this.user = this.sessionService.getSession()
    this.getProjects()

  }

  getProjects(){
    this.projectsService.myProjects(this.user.id).then((res)=>{
      console.log({res})
      this.projects = res.data
    })
  }

}
