<app-navbar></app-navbar>

<div class="main-content min-vh-100">

  <section>
    <div class="container">

      <!-- BREADCRUMB -->
      <div class="row mt-3">
        <div class="col">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/">Projetos</a>
              </li>
              <li class="breadcrumb-item active">
                Detalhes
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <!-- NAVIGATION TABS -->
      <div class="row justify-content-center mb-2">
        <div class="col">
          <h2 class="mb-0 pt-3">
            Detalhes do projeto
          </h2>
          <p class="lead mb-4 pb-3 border-bottom font-weight-bold text-secondary">
            {{ item?.title }}
          </p>
          <ul class="nav mb-3">
            <!--<li class="nav-item">
              <a href="javascript:void(0)" (click)="changeTab('about')" class="nav-link btn"
                [ngClass]="{'active': currentTab == 'about'}">
                Projeto
              </a>
            </li> -->
            <!-- <li class="nav-item">
              <a href="javascript:void(0)" (click)="changeTab('schedule')" class="nav-link btn"
                [ngClass]="{'active': currentTab == 'schedule'}">
                Andamento
              </a>
            </li> -->
          <!-- <li class="nav-item">
              <a href="javascript:void(0)" (click)="changeTab('financial')" class="nav-link btn"
                [ngClass]="{'active': currentTab == 'financial'}">
                Financeiro
              </a>
            </li> -->
            <!-- <li class="nav-item">
              <a href="javascript:void(0)" (click)="changeTab('documentation')" class="nav-link btn"
                [ngClass]="{'active': currentTab == 'documentation'}">
                Documentação
              </a>
            </li> -->
          </ul>
        </div>
      </div>

      <!-- ABOUT PROJECT -->
      <div class="row justify-content-start" *ngIf="currentTab == 'about'">
        <div class="col-12" *ngIf=item?.overduePayment>
          <div class="alert alert-primary" role="alert">
            Você possui pagamentos pendentes do seu projeto.
            <a class="font-weight-bold" (click)="changeTab('financial')">
              Clique aqui
            </a>
            para realizar o pagamento.
          </div>
        </div>
        <div class="col-12">
          <div class="card card-body">
            <div class="row">
              <div class="col">
                <span class="h4">
                  Desenvolvimento
                </span>
                <strong class="font-weight-bold text-muted">
                  &nbsp;
                  Iniciado dia 12/04/20
                </strong>
              </div>
              <div class="col-auto">
                <span class="lead font-weight-bold"
                  [ngClass]="{'text-success' : devProgress  == 2, 'text-secondary': devProgress == 0, 'text-primary' : devProgress == 1}">
                  <i class="fas fa-circle"></i>
                  {{devProgress == 2 ? 'Finalizado' : devProgress == 0 ? 'Aguardando' : 'Em andamento'}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="item?.recurring.length">
          <div class="card card-body">
            <div class="row">
              <div class="col">
                <span class="h4">
                  Manutenção
                </span>
              </div>
              <div class="col-auto">
                <span class="lead font-weight-bold "
                  [ngClass]="{'text-success' : maintenanceProgress  == 2, 'text-secondary': maintenanceProgress == 0, 'text-primary' : devProgress == 1}">
                  <i class="fas fa-circle"></i>
                  {{maintenanceProgress == 2 ? 'Finalizado' : maintenanceProgress == 0 ? 'Aguardando' : 'Em andamento'}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 d-flex" *ngFor="let p of item?.products">
          <div class="card card-body">
            <h4 class="mb-1">
              <i [class]="icon(p.type)"></i>
              {{p?.title}}
            </h4>
            <span class="font-weight-bold mb-2 text-secondary">
              {{p.description}}
            </span>
            <div>
              <a href="{{p.link}}" target="_blank" class="">
                Abrir
              </a>
            </div>
          </div>
        </div>

      </div>

      <!-- PROJECT SCHEDULE -->
      <div class="row justify-content-center" *ngIf="currentTab == 'schedule'">
        <div class="col">
          <div class="row">
            <div class="col-md-12 d-flex">
              <div class="card card-body">
                <h4>
                  Andamento
                </h4>
                <p>
                  Aqui você pode acompanhar o status individual de cada produto do seu projeto. Cada status é atualizado
                  periódicamente de acordo com o nosso gerente de projetos junto com o time de desenvolvedores for
                  avançando o projeto.
                </p>



                <div class="border-bottom mb-3 mt-1" *ngFor="let p of item?.products">
                  <div class="d-flex mb-1">
                    <strong class="flex-fill">
                      {{p?.title}}
                      <span class="text-muted">
                        (Previsão de {{p.diffDays}} dias)
                      </span>
                    </strong>
                    <small class="text-primary"
                      [ngClass]="{'text-success' : p.progress >=100, 'text-secondary': p.progress == 0}">
                      {{p.progress >=100 ? 'Finalizado' : p.progress == 0? 'Aguardando' : 'Em andamento'}}
                    </small>
                  </div>
                  <div class="progress mb-3">
                    <div class="progress-bar p-2 bg-primary "
                      [ngClass]="{'bg-success' : p.progress >=100, 'bg-secondary': p.progress == 0}" role="progressbar"
                      [ngStyle]="{'width': p.progress > 0 ? p.progress + '%' : 5 + '%'}" aria-valuemin="0"
                      aria-valuemax="100">
                      <span>
                        {{p.progress}}%
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- PROJECT FINANCIAL -->
      <div class="row justify-content-center" *ngIf="currentTab == 'financial'">
        <!-- <div class="col-12">
          <div class="card card-body">
            <div class="row mb-3">
              <div class="col">
                <span class="h4">
                  Projeto
                </span>
              </div>
              <div class="col-auto">
                <span class="lead font-weight-bold">
                  {{ helper.getFormattedPrice(item?.totalPayment) }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <table class="table table-hover">
                  <tbody>
                    <tr *ngFor="let p of item?.payments; let i = index">
                      <td>{{i+1}}º parcela</td>
                      <td>{{p.due_date | date: 'dd/MM/yyyy'}}</td>
                      <td>{{helper.paymentMethod(p.method)}} </td>
                      <td class="text-right"
                        [ngClass]="{'text-success' : p.status == 'paid', 'text-primary' : p.shouldPay}">
                        <span *ngIf="p.shouldPay">

                          <a [routerLink]="[PathRoutes.Payment]" [queryParams]="p" class="text-primary">Realizar
                            pagamento</a>

                        </span>
                        <span *ngIf=!p.shouldPay>{{ helper.paymentStatus(p.status) }} </span>




                        <i class="" [ngClass]="{'fas fa-check-circle' : p.status == 'paid'}"></i>
                      </td>
                      <td class="text-right"> {{ helper.getFormattedPrice(p.value) }} </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-12" *ngFor="let p of item?.recurring; let i = index">
          <div class="card card-body">
            <div class="row mb-3">
              <div class="col">
                <span class="h4">
                  {{p.obs ? p.obs  : 'Pagamento recorrente'}}
                </span>
              </div>
              <div class="col-auto">
                <span class="lead font-weight-bold">
                  <span
                  *ngIf="p.shouldPay">

                  <a [routerLink]="[PathRoutes.Payment]" [queryParams]="p" class="text-primary">Realizar
                    pagamento</a>

                </span>
                <span *ngIf=!p.shouldPay>{{ helper.paymentStatus(p.status, 2) }} 
                  
                  <!-- <span *ngIf="p.status == 'unpaid'">
                    
                    <a class="text-primary" style="cursor:pointer" data-toggle="modal" data-target="#modalPaymentHistory" (click)="setPaymentHistory(p.id)">Visualizar boleto</a>  
                    
                    </span> -->
                
                </span>

                <i class="" [ngClass]="{'fas fa-check-circle' : p.status == 'paid'}"></i>
                </span>
              </div>
            </div>
            <hr class="my-0" *ngIf="p?.transactions.length > 0">

            <div class="row">

              <div class="col-12">
                <table class="table table-hover">
                  <tbody>
                    <tr *ngFor="let t of p?.transactions; let i = index">
           
                      <td [ngClass]="{'border-0': i == 0}">{{ t.due_date | date: 'dd/MM/yyyy' }}</td>
                      <td [ngClass]="{'border-0': i == 0}">{{helper.paymentMethod(t.payment_method)}}</td>
                      <td [ngClass]="{'border-0': i == 0}" class="text-right">{{ helper.toCurrencyString(t.amount,1) }} </td>
                      <td [ngClass]="{'border-0': i == 0}" class="text-right">

                        <span *ngIf="t.status != 'waiting_payment'">

                         {{ helper.paymentStatus(t.status, 1) }} 
                          
                        </span>
                      

                    
                        <a href="{{ t.boleto_url }}" target="_blank" *ngIf="t.status == 'waiting_payment'">Pagar agora</a>


                        </td>

                        <td>    <a href="{{ environment.s3_base_url + t.danfe }}" target="_blank" *ngIf="t.danfe">Download NFe</a>

                          <p *ngIf="!t.danfe">-</p>
                        </td>

                      <td [ngClass]="{'border-0': i == 0}" class="text-right">
                        <i class="fas"
                          [ngClass]="{'fa-check-circle text-success': t.status == 'paid', 'fa-clock text-warning': t.status == 'pending', 'fa-times-circle text-danger': t.status == 'unpaid', 'fa-clock text-warning': t.status == 'waiting_payment'}"></i>
                      </td>

                    </tr>

                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
       
      </div>

      <!-- PROJECT DOCUMENTATION -->
      <div class="row justify-content-center" *ngIf="currentTab == 'documentation'">
        <div class="col-12 col-md-6 d-flex" *ngFor="let f of item?.files">
          <div class="card card-body">
            <h4 *ngIf="f.type == 'proposal'">
              Proposta comercial
            </h4>
            <h4 *ngIf="f.type == 'contract'">
              Contrato
            </h4>
            <h4 *ngIf="f.type == 'other'">
              Arquivo adicional
            </h4>

            <p>
              {{f?.title}}
            </p>
            <a href="{{s3_base_url + f.path}}" target="_blank">
              <i class="fas fa-file-pdf"></i>
              Abrir documento
            </a>
          </div>
        </div>

      </div>

    </div>
  </section>
</div>
<div class="modal fade" id="modalPaymentHistory" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Pagamentos recorrentes</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-center" *ngIf="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <table class="table table-hover" *ngIf="!loading">
          <tbody>
            <tr *ngFor="let p of paymentHistory; let i = index">
              <td [ngClass]="{'border-0': i == 0}">{{ i+1 }}º parcela</td>
              <td [ngClass]="{'border-0': i == 0}">{{ p.due_date | date: 'dd/MM/yyyy' }}</td>
              <td [ngClass]="{'border-0': i == 0}">{{helper.paymentMethod(p.payment_method)}}</td>
              <td [ngClass]="{'border-0': i == 0}" class="text-right">{{ helper.toCurrencyString(p.amount, 2) }}</td>
              <td [ngClass]="{'border-0': i == 0}" class="text-right">
                
                <a href="{{ p.boleto_url }}" target="_blank">Pagar</a>
                 </td>

              <td [ngClass]="{'border-0': i == 0}" class="text-right">
                <i class="fas"
                  [ngClass]="{'fa-check-circle text-success': p.status == 'paid', 'fa-clock text-warning': p.status == 'pending', 'fa-times-circle text-danger': p.status == 'unpaid', 'fa-clock text-warning': p.status == 'waiting_payment'}"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
