import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/helpers/helper.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';
import { SessionService } from 'src/app/auth/session.service';

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditComponent implements OnInit {

  PathRoutes = PathRoutes
  loading = false
  form: FormGroup
  session: any

  constructor(
    public helper: HelperService,
    private clientsService: ClientsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.session = this.sessionService.getSession()
    this.setupForm()
  }

  setupForm() {
    this.form = this.formBuilder.group({
      'id': [this.session.id],
      'name': [this.session.name, Validators.required],
      'email': [this.session.email, Validators.compose([Validators.required, Validators.email])],
      'company': [this.session.company, Validators.required],
      'document': [this.session.document, Validators.required],
      'phone': [this.session.phone, Validators.required],
    })
  }

  save() {
    this.loading = true
    this.clientsService.save(this.form.getRawValue())
      .then((res: any) => {
        if (res.status) {
          this.helper.triggerNotification(true)
          this.router.navigate([PathRoutes.Account])
          this.sessionService.saveSession({ ...this.session, ...res.data })
        } else {
          this.helper.triggerNotification(false)
        }
      })
      .catch(e => console.log(e))
      .finally(() => {
        this.loading = false
      })
  }

}
