import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PathRoutes } from './../../routes/path-routes';
import { environment } from 'src/environments/environment';

import pagarme from 'pagarme/browser'

import { SessionService } from 'src/app/auth/session.service';
import { PaymentService } from 'src/app/services/payment.service';
import { HelperService } from 'src/app/helpers/helper.service';

import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { FormValidatorsService } from 'src/app/services/form-validators.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  PathRoutes = PathRoutes


  cardValidation = { status: true, message: '' }
  pagarme = pagarme
  cardForm: FormGroup
  loading = false
  boletoUrl : any
  item

  cardHash

  constructor(
    private sanitizer: DomSanitizer,
    private sessionService: SessionService,
    private payService: PaymentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public helper: HelperService,
    private formValidators: FormValidatorsService,
    private formBuilder: FormBuilder,
    private projectsService: ProjectsService,


  ) {



  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      this.item = params
      console.log(this.item)

    });

    this.setupForm()


  }



  setupForm() {
    this.cardForm = this.formBuilder.group({
      "method": [this.item.method ? this.item.method : 'boleto'],
      "card_holder_name": [''],
      "card_expiration_date": [''],
      "card_number": [''],
      "card_cvv": [''],

    })

  }




  runCardHash() {
    let formValues = this.cardForm.getRawValue()

    var card = {
      card_holder_name: formValues.card_holder_name,
      card_expiration_date: formValues.card_expiration_date,
      card_number: formValues.card_number,
      card_cvv: formValues.card_cvv
    }

    // pega os erros de validação nos campos do form e a bandeira do cartão
    var cardValidations = pagarme.validate({ card: card })

    //Então você pode verificar se algum campo não é válido
    if (!cardValidations.card.card_number || !cardValidations.card.card_expiration_date || !cardValidations.card.card_holder_name || !cardValidations.card.card_cvv) {

      this.cardValidation = { status: false, message: 'Cartão inválido. Verifique as informações digitadas.' }
    } else {
      //Mas caso esteja tudo certo, você pode seguir o fluxo
      pagarme.client.connect({ encryption_key: environment.pagarme_key })
        .then(client => client.security.encrypt(card))
        .then(card_hash => this.cardHashOk(card_hash))

    }



  }

  cardHashOk(h) {
    this.cardHash = h
    this.send()
  }
  submit() {
    let formValues = this.cardForm.getRawValue()

    this.loading = true

    if (formValues.method == 'credit_card') {
      this.runCardHash()
    } else {
      this.send()
    }

  }

  send() {
    let formValues = this.cardForm.getRawValue()

    let payload = {
      method: formValues.method,
      card_hash: this.cardHash
    }

    this.payService.pay(this.item.type, this.item.id, payload)
      .then((res: any) => {
        console.log({ res })
        if (res.status) {
          if (formValues.method == 'credit_card') {
            this.helper.triggerNotification(true,'Sucesso!','Pagamento realizado com sucesso.')
          this.router.navigate([PathRoutes.ProjectDetails + '/' + this.item.project_id])

          } else {
            this.boletoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.pagarme.current_transaction.boleto_url)

            // this.getPaymentHistory()
            // this.helper.triggerNotification(true,'Sucesso!','Seu boleto está sendo gerado e você o receberá por email. Também poderá localizá-lo na sessão financeira desta plataforma.')
          }
        } else {
          this.helper.triggerNotification(false)
        }
      })
      .catch(e => {
        this.loading = false
        this.helper.triggerNotification(false)
      })
      .finally(() => this.loading = false)
  }






  getPaymentHistory(){
    this.projectsService.getPaymentHistory(this.item.id)
      .then((res: any) => {
        if (res.status) {

          if(res.data.length){
           var payment = res.data[0]
           this.boletoUrl = res.data[0].boleto_url
          }

        } else {
          this.loading = false
        }
      })
      .catch(e => {
        this.loading = false
        console.log(e)
      })
      .finally(() => {
        this.loading = false
      })
  }


}
