<app-navbar></app-navbar>
<div class="main-content min-vh-100">
  <section>
    <div class="container">
      <div class="row mt-3">
        <div class="col">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a [routerLink]="PathRoutes.Account.Detail">Minha conta</a>
              </li>
              <li class="breadcrumb-item">
                <a [routerLink]="PathRoutes.Account.Edit">Editar</a>
              </li>
              <li class="breadcrumb-item active">
                Alterar senha
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col">
          <h2 class="mb-0 pt-3">
            Alterar senha
          </h2>
        </div>
        <div class="col-12">
          <hr>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <form [formGroup]="form" (submit)="save()">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Senha atual</label>
                  <input type="password" formControlName="old_password" class="form-control shadow-sm"
                    placeholder="Senha"
                    [ngClass]="{'is-invalid': form.controls.old_password.touched && form.controls.old_password.invalid }">
                  <small *ngIf="form.controls.old_password.touched && form.controls.old_password.invalid"
                    class="text-danger">Preencha a sua senha (com no mínimo de 6 caracteres)</small>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Nova senha</label>
                  <input type="password" formControlName="password" class="form-control shadow-sm" placeholder="Senha"
                    [ngClass]="{'is-invalid': form.controls.password.touched && form.controls.password.invalid }">
                  <small *ngIf="form.controls.password.touched && form.controls.password.invalid"
                    class="text-danger">Preencha
                    a sua senha (com no mínimo de 6 caracteres)</small>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Confirme sua nova senha</label>
                  <input type="password" formControlName="confirm_password" class="form-control shadow-sm"
                    placeholder="Confirme sua senha"
                    [ngClass]="{'is-invalid': form.controls.confirm_password.touched && form.controls.confirm_password.value != form.controls.password.value }">
                  <small
                    *ngIf="form.controls.confirm_password.touched && form.controls.confirm_password.value != form.controls.password.value"
                    class="text-danger">Senhas não coicidem</small>
                </div>
              </div>
              <div class="col">
                <button type="submit" class="btn btn-block btn-primary mt-4" [disabled]="form.invalid">
                  <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span *ngIf="!loading">
                    Concluir
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</div>
